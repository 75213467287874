@import "includes/custom";
@import "includes/bootstrap";

/*
 * Globals
 */

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i,800");

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
    color: $dark;
    text-shadow: none; /* Prevent inheritance from `body` */
    background-color: $white;
    border: .05rem solid $white;
}


/*
 * Base structure
 */

html,
body {
    height: 100%;
	background-image: linear-gradient(rgba($green, 0.2), rgba($green, 0.3)), url(../images/header.jpg);
    background-size: cover;
    background-position: center center;
}

body {
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    text-shadow: 0 .05rem .1rem rgba($dark, .5);
    box-shadow: inset 0 0 5rem rgba($dark, .5);
}

.cover-container {
    max-width: 42em;
    background: rgba($black, .5);
}


/*
 * Header
 */
.masthead {
    margin-bottom: 2rem;
}

.masthead-brand {
    margin-bottom: 0;

    img {
        width: 200px;
    }
}

.nav-masthead {
    .nav-link {
        padding: .25rem 0;
        font-weight: 700;
        color: rgba($white, .5);
        background-color: transparent;
        border-bottom: .25rem solid transparent;

        &:hover,
        &:focus {
            border-bottom-color: rgba($white, .25);
        }

        + .nav-link {
            margin-left: 1rem;
        }
    }
    .active {
        color: $white;
        border-bottom-color: $white;
    }
}

@media (min-width: 48em) {
    .masthead-brand {
        float: left;
    }
    .nav-masthead {
        float: right;
    }
}


/*
 * Cover
 */
.cover {
    padding: 0 1.5rem;

    .btn-lg {
        padding: .75rem 1.25rem;
        font-weight: 700;
    }

    a {
        border-bottom: 1px solid $primary;
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
}


/*
 * Footer
 */
.mastfoot {
    color: rgba($white, .5);
}

.modal {
    text-shadow: none;
    
    h5, label {
        color: $dark;
    }

    .btn-primary {
        color: $white;
    }
}
